import React from 'react'
import Currency from './components/Currency'

const App = () => {
  return (
    
    <div  style={{ display: 'flex', flexDirection: 'column', justifyItems: 'center', alignItems: 'center'}}>
    <Currency/>
    </div>
  )
}

export default App